import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import Section from '../../../Core/Layout/Section/Section'

const Footer = ({ className, ...rest }: FooterProps) => {
  return (
    <div className={`bg-primary text-light font-bold ${className}`} {...rest}>
      <Section>
        <div className="flex flex-wrap justify-around items-center py-4">
          <div>
            Made with ❤️ by{' '}
            <a
              className="underline"
              href="https://www.youtube.com/channel/UCvmINlrza7JHB1zkIOuXEbw"
              target="_BLANK"
              rel="noreferrer"
            >
              Mike
            </a>{' '}
            and Joe Dane
          </div>
          <div className="md:mt-0 mt-2">info@simulator.dev</div>
        </div>
      </Section>
    </div>
  )
}

export interface FooterProps extends HtmlDiv {}

export default styled(observer(Footer))``
