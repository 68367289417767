import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import HeaderLinks from '../HeaderLinks'
import ThemeToggleIcon from '../../../../../utils/ThemeToggleIcon/ThemeToggleIcon'
import Icon from '../../../../../Core/Content/Icon/Icon'
import { HtmlDiv } from '../../../../../../utils/types'
import DrawerTrigger from '../../../../../Core/Surfaces/Drawer/Trigger/DrawerTrigger'

const ResponsiveHeaderLinks = ({
  className,
  ...rest
}: ResponsiveHeaderLinksProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <HeaderLinks className="hidden lg:flex" />

      <div className="lg:hidden flex items-center">
        <ThemeToggleIcon className="mx-2 mr-8" />
        <DrawerTrigger
          Trigger={<Icon className="text-xl cursor-pointer" name="hamburger" />}
        >
          <HeaderLinks className="w-60 text-base" />
        </DrawerTrigger>
      </div>
    </div>
  )
}

export interface ResponsiveHeaderLinksProps extends HtmlDiv {}

export default styled(observer(ResponsiveHeaderLinks))``
