import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Section from '../../../Core/Layout/Section/Section'
import SocialLinks from './SocialLinks/SocialLinks'
import Link from '../../../Core/Navigation/Link/Link'
import Logo from './Logos/Logo/Logo'
import CoworkersLogo from './Logos/CoworkersLogo/CoworkersLogo'
import ResponsiveHeaderLinks from './Links/Responsive/ResponsiveHeaderLinks'
import { HtmlDiv } from '../../../../utils/types'
import NewUserWizard from './NewUserWizard/NewUserWizard'
import useApp from '../../../AppContextProvider/hooks/useApp'

const Header = ({ className, ...rest }: HeaderProps) => {
  const app = useApp()

  const newUserLoggedIn =
    app.user &&
    (!app.user.isEmailVerified || app.user.avatar.avatarName.value === '')

  return (
    <div className={`relative bg-primary ${className}`} {...rest}>
      <Section
        className={`p-2 flex flex-row flex-wrap items-center justify-between text-light`}
      >
        <Link to="/" className="flex items-center flex-shrink-0">
          <Logo className="logo w-36 sm:w-56" />
          <CoworkersLogo className="coworkers-logo xl:block hidden w-24 ml-2" />
        </Link>
        <ResponsiveHeaderLinks />
      </Section>
      {newUserLoggedIn && <NewUserWizard />}
      <SocialLinks className="ml-8" />
    </div>
  )
}

export interface HeaderProps extends HtmlDiv {}

export default styled(observer(Header))`
  .coworkers-logo {
    max-width: 6rem;
  }
  .logo {
    max-width: 14rem;
  }
`
