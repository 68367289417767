import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import Icon from '../../Content/Icon/Icon'

const CLOSING_DURATION = 0.25

const Drawer = ({
  children,
  onClose = () => {},
  className,
  ...rest
}: DrawerProps) => {
  const [closing, setClosing] = useState(false)

  const handleClose = () => {
    setClosing(true)
    setTimeout(onClose, CLOSING_DURATION * 1000 - 10)
  }

  return (
    <div
      className={`fixed h-screen w-full fixed inset-0 z-20 cursor-pointer ${className}`}
      onClick={handleClose}
    >
      <div
        className={`drawer fixed pt-8 px-4 bg-base top-0 right-0 overflow-scroll cursor-default ${
          closing && 'drawer-exit'
        }`}
        onClick={(e) => e.stopPropagation()}
        {...rest}
      >
        <Icon
          name="close"
          className="cursor-pointer absolute right-0 top-0 m-2 text-base text-xl"
          id="drawer_close-button"
          onClick={handleClose}
        />

        {children}
      </div>
      <div className="backdrop fixed w-full h-screen bg-accent -z-10"></div>
    </div>
  )
}

export interface DrawerProps extends HtmlDiv {
  onClose?: () => void
}

export default styled(observer(Drawer))`
  .drawer {
    border: 3px solid ${(props) => props.theme.text.colors.base};
    border-radius: 1rem 0 0 1rem;
    height: 90vh;
    animation: slidein-drawer;
    animation-duration: 0.25s;
    max-width: 90%;
  }

  > .backdrop {
    opacity: 0.3;
  }

  .drawer-exit {
    animation: slideout-drawer;
    animation-duration: ${`${CLOSING_DURATION}s`};
  }

  @keyframes slidein-drawer {
    0% {
      right: -15rem;
    }
  }
  @keyframes slideout-drawer {
    100% {
      right: -15rem;
    }
  }
`
