import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../../../utils/types'
import useApp from '../../../../../AppContextProvider/hooks/useApp'

const VerifyEmailReminder = ({
  className,
  ...rest
}: VerifyEmailReminderProps) => {
  const app = useApp()

  return (
    <div className={`${className}`} {...rest}>
      To reserve your avatar name, please verify your email by clicking the link
      in the verification email we sent you (
      <span
        className="underline font-bold cursor-pointer"
        onClick={async () => {
          try {
            await app.user?.sendVerificationEmail()
            alert('verification email re-sent')
          } catch (e) {
            alert('We just sent a new one, check your email!')
          }
        }}
      >
        resend)
      </span>
    </div>
  )
}

export interface VerifyEmailReminderProps extends HtmlDiv {}

export default styled(observer(VerifyEmailReminder))``
