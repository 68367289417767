import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../utils/types'
import { Helmet } from 'react-helmet'
import Footer from './Footer/Footer'
import Header from './Header/Header'

const Layout = ({
  children,
  seoTitle,
  seoDescription,
  className,
  ...rest
}: LayoutProps) => {
  return (
    <div className={`${className}`} {...rest}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={seoDescription} />
        <title>
          {seoTitle
            ? `${seoTitle} | Dev Simulator`
            : 'Dev Simulator - A Coding RPG & Bootcamp'}
        </title>
      </Helmet>
      <div className="relative flex flex-col wrapper ">
        <Header className="sticky z-10 top-0" />
        <div className="flex-grow">{children}</div>
        <Footer />
      </div>
    </div>
  )
}

export interface LayoutProps extends HtmlDiv {
  seoTitle?: string
  seoDescription?: string
}

export default styled(observer(Layout))`
  .wrapper {
    min-height: 100vh;
  }
`
