import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../../utils/types'
import Link from '../../../../Core/Navigation/Link/Link'
import tw from 'twin.macro'
import useApp from '../../../../AppContextProvider/hooks/useApp'
import Spinner from '../../../../Core/Spinner/Spinner'
import ModalTrigger from '../../../../Core/Surfaces/Modal/Trigger/ModalTrigger'
import Auth from '../../../../Auth/Auth'
import { truncate } from 'lodash'
import ThemeToggleIcon from '../../../../utils/ThemeToggleIcon/ThemeToggleIcon'
import Icon from '../../../../Core/Content/Icon/Icon'

const HeaderLinks = ({ className, ...rest }: HeaderLinksProps) => {
  const app = useApp()

  return (
    <div
      className={`flex flex-col-reverse lg:flex-row items-center flex-wrap-reverse ${className}`}
      {...rest}
    >
      <Link to="/docs" className="header-link font-bold">
        Game Manual
      </Link>
      <Link to="/#bootcamp" className="header-link font-bold">
        Bootcamp
      </Link>
      <Link to="/open-source" className="header-link font-bold">
        Open Source
      </Link>
      <ThemeToggleIcon className="hidden lg:block" />
      <div className="hidden lg:block header-link font-bold">|</div>
      {app.isInitializingUser && <Spinner className="mx-16 my-1" size="1rem" />}
      {!app.isInitializingUser && !app.user && (
        <ModalTrigger
          name="Yo, Welcome!"
          Trigger={
            <div id="auth-modal-trigger" className="underline cursor-pointer">
              Sign Up / In
            </div>
          }
          closeOnClickOff={false}
        >
          <Auth className="w-xs text-base" />
        </ModalTrigger>
      )}
      {app.user && (
        <div className="underline">
          {truncate(app.user.avatar?.avatarName.value || app.user.email, {
            length: 23,
          })}
        </div>
      )}
      {app.user && (
        <Icon
          className="cursor-pointer ml-3 font-bold text-xl"
          name="signOut"
          id="sign-out-button"
          onClick={() => app.signOut()}
        />
      )}
    </div>
  )
}

export interface HeaderLinksProps extends HtmlDiv {}

export default styled(observer(HeaderLinks))`
  .header-link {
    ${tw`cursor-pointer text-lg lg:mx-4 my-1`}
  }
`
