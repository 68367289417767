import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'

const Section = ({
  children,
  className,
  wrapperClassName,
  ...rest
}: SectionProps) => {
  return (
    <div
      className={`${wrapperClassName} w-full flex justify-center `}
      {...rest}
    >
      <div className={`${className} w-xl px-4 mx-3`}>{children}</div>
    </div>
  )
}

export interface SectionProps extends HtmlDiv {
  wrapperClassName?: string
}

export default styled(observer(Section))``
