import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../../utils/types'
import useApp from '../../../../AppContextProvider/hooks/useApp'
import Section from '../../../../Core/Layout/Section/Section'
import VerifyEmailReminder from './VerifyEmailReminder/VerifyEmailReminder'
import AvatarNameForm from './AvatarNameForm/AvatarNameForm'

const NewUserWizard = ({ className, ...rest }: NewUserWizardProps) => {
  const app = useApp()

  return (
    <div className={`bg-primary text-light ${className}`} {...rest}>
      <Section>
        {app.user && !app.user.isEmailVerified && (
          <VerifyEmailReminder className="py-4" />
        )}
        {app.user &&
          app.user.isEmailVerified &&
          app.user.avatar.avatarName.value === '' && (
            <AvatarNameForm className="max-w-xs" />
          )}
      </Section>
    </div>
  )
}

export interface NewUserWizardProps extends HtmlDiv {}

export default styled(observer(NewUserWizard))``
