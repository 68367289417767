import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Icon from '../../Core/Content/Icon/Icon'
import useApp from '../../AppContextProvider/hooks/useApp'
import LightTheme from '../../../models/Theme/Light/LightTheme'
import DarkTheme from '../../../models/Theme/Dark/DarkTheme'
import { HtmlDiv } from '../../../utils/types'

const ThemeToggleIcon = ({ className, ...rest }: ThemeToggleIconProps) => {
  const app = useApp()

  return (
    <Icon
      id="theme-toggle-icon"
      className={`cursor-pointer ${className}`}
      name={app.theme.id === LightTheme.id ? 'moon' : 'sun'}
      onClick={() => {
        app.theme =
          app.theme.id === LightTheme.id ? new DarkTheme() : new LightTheme()
      }}
      {...rest}
    />
  )
}

export interface ThemeToggleIconProps extends HtmlDiv {}

export default styled(observer(ThemeToggleIcon))``
