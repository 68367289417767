import { observer } from 'mobx-react-lite'
import React from 'react'
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../../utils/types'

const SocialLinks = ({ className, ...rest }: SocialLinksProps) => {
  return (
    <div className={`inline absolute items-center  ${className}`} {...rest}>
      <div className="flex items-center text-2xl p-2 bg-base container">
        <div className="font-bold text-base ml-1">share</div>

        <a
          href={`https://twitter.com/intent/tweet?text=Dev Simulator - A Coding RPG and Bootcamp&url=https://simulator.dev`}
          target="_BLANK"
          rel="noreferrer"
        >
          <FaTwitter className="text-blue-500 ml-1" />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=https://simulator.dev&quote=Dev Simulator - A Coding RPG and Bootcamp`}
          target="_BLANK"
          rel="noreferrer"
        >
          <FaFacebook className="text-indigo-500 mx-1" />
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=https://simulator.dev&title=Dev Simulator - A Coding RPG and Bootcamp&source=https://simulator.dev`}
          target="_BLANK"
          rel="noreferrer"
        >
          <FaLinkedin className="text-blue-500" />
        </a>
      </div>
    </div>
  )
}

export interface SocialLinksProps extends HtmlDiv {}

export default styled(observer(SocialLinks))`
  bottom: -2rem;
  .container {
    border-bottom: 0.15rem solid ${(props) => props.theme.text.colors.base};
    border-right: 0.15rem solid ${(props) => props.theme.text.colors.base};
    border-left: 0.15rem solid ${(props) => props.theme.text.colors.base};
    width: 9.5rem;
    height: 2rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`
