import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../../../utils/types'
import useApp from '../../../../../AppContextProvider/hooks/useApp'

import Form from '../../../../../Core/Inputs/Form/Form'
import UIForm from '../../../../../../models/UIFieldCollection/UIForm/UIForm'

const AvatarNameForm = ({ className, ...rest }: AvatarNameFormProps) => {
  const app = useApp()
  const avatarNameForm = app.user?.avatar.getAvatarNameForm() as UIForm

  return (
    <div className={`${className}`} {...rest}>
      <Form uiForm={avatarNameForm} />
    </div>
  )
}

export interface AvatarNameFormProps extends HtmlDiv {}

export default styled(observer(AvatarNameForm))`
  .form-field-caption {
    color: ${(props) => props.theme.text.colors.light} !important;
  }
  .form-submit-button {
    background: ${(props) => props.theme.background.colors.base} !important;
    color: ${(props) => props.theme.text.colors.base} !important;
  }
  .spinner {
    span {
      border-color: ${(props) => props.theme.text.colors.base} !important;
    }
  }
  .form-error-message {
    color: ${(props) => props.theme.text.colors.light} !important;
  }
`
