import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Drawer, { DrawerProps } from '../Drawer'

const DrawerTrigger = ({
  onClose,
  Trigger,
  children,
  className,
  ...rest
}: DrawerTriggerProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <div className={`${className}`}>
      <div onClick={() => setDrawerOpen(true)}>{Trigger}</div>
      {drawerOpen && (
        <Drawer
          onClose={() => {
            setDrawerOpen(false)
            if (onClose) onClose()
          }}
          {...rest}
        >
          {children}
        </Drawer>
      )}
    </div>
  )
}

export interface DrawerTriggerProps extends DrawerProps {
  Trigger: JSX.Element | string
}

export default styled(observer(DrawerTrigger))``
